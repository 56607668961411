import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FormatearNumeroConSeparador from "../../utils/formatNumber";
import dateToInput from "../../utils/DateToInput";
import { useEffect, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

export default function ModalNewBill({
  openModalNewBill,
  handleCloseModal,
  centers,
  mainDataNewBill,
  setMainDataNewBill,
  SubmitmodalBill,
  loadApi,
  newBillError,
  showAdditionalFields,
  setShowAdditionalFields,
  mainDataNewRate,
  setMainDataNewRate,
  mainDataNewRateError,
  mainDataQuery,
  setMainDataQuery,
}) {
  const VisuallyHiddenInput = (props) => (
    <input
      type="file"
      style={{ display: "none" }}
      accept=".xlsx,.xls,.doc,.docx,.pdf,.txt,image/*"
      {...props}
    />
  );
  const [rates, setRates] = useState();

  const [newextFieldcreate, setNewextFieldcreate] = useState(false);
  useEffect(() => {
    loadApi(`rate/rates/${mainDataNewBill.center_id}`, true, "get")
      .then((response) => {
        setRates(response.data);
      })
      .catch((e) => {});
  }, [mainDataNewBill.center_id]);

  useEffect(() => {
    if (showAdditionalFields === true) {
      setNewextFieldcreate(true);
      setMainDataNewBill((adata) => ({
        ...adata,
        amount: "",
      }));
    } else {
      setNewextFieldcreate(false);
    }
  }, [showAdditionalFields]);
  useEffect(() => {
    setMainDataNewBill((adata) => ({
      ...adata,

      name_rate: mainDataNewRate.name,
      amount: mainDataNewRate.price,
      term: mainDataNewRate.term,
    }));
  }, [mainDataNewRate]);

  useEffect(() => {
    setMainDataQuery((adata) => ({
      ...adata,
      center_id: mainDataNewBill.center_id,
      date: mainDataNewBill.date,
      note: mainDataNewBill.method,
    }));
  }, [mainDataNewBill]);

  return (
    <>
      <Dialog
        open={openModalNewBill}
        onClose={() => {
          setRates(null);
          handleCloseModal();
        }}
        PaperProps={{
          component: "form",
        }}
        disableScrollLock
        sx={{ overflow: "hidden" }}
      >
        <DialogTitle>Agregar nuevo ingreso</DialogTitle>

        <DialogContent>
          <Box sx={{ width: { md: 500 } }}>
            <Grid
              container
              direction="row"
              spacing={2}
              marginTop={1}
              sx={{ mb: 2 }}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">Centro</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Centro"
                    id="demo-simple-select"
                    sx={{ borderRadius: 2 }}
                    value={mainDataNewBill.center_id}
                    onChange={({ target }) =>
                      setMainDataNewBill((adata) => ({
                        ...adata,
                        center_id: target.value,
                      }))
                    }
                    error={newBillError.center_id}
                    required
                  >
                    <MenuItem value="" disabled>
                      Seleccione una opción...
                    </MenuItem>
                    {centers &&
                      (centers || []).map((row, index) => (
                        // recorrer centros row.id sera value y se mostrara row.name
                        <MenuItem
                          value={row.id}
                          onClick={() => {
                            setMainDataNewBill((adata) => ({
                              ...adata,
                            }));
                          }}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Fecha "
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  variant="outlined"
                  fullWidth
                  onChange={({ target }) =>
                    setMainDataNewBill((adata) => ({
                      ...adata,
                      date: target.value,
                    }))
                  }
                  error={newBillError.date}
                  value={dateToInput(mainDataNewBill.date)}
                  required
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                {/*  */}
                <FormControl fullWidth required disabled={newextFieldcreate}>
                  <InputLabel id="demo-simple-select-label">
                    Nombre de la tarifa
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Nombre de la tarifa"
                    id="demo-simple-select"
                    sx={{ borderRadius: 2 }}
                    //debe carmbiar para poner nombre tarifa
                    value={mainDataNewBill.name_rate}
                    onChange={({ target }) =>
                      setMainDataNewBill((adata) => ({
                        ...adata,
                        name_rate: target.value,
                      }))
                    }
                    // error={newBillError.amount}
                    required
                  >
                    <MenuItem value="" disabled>
                      Seleccione una opción...
                    </MenuItem>
                    {rates?.length < 1 && (
                      <MenuItem value={""}>
                        <Alert severity="warning" sx={{ width: "100%" }}>
                          El centro no tiene tarifas.
                        </Alert>
                      </MenuItem>
                    )}
                    {rates &&
                      (rates || []).map((row, index) => (
                        // recorrer centros row.id sera value y se mostrara row.name
                        <MenuItem
                          value={row?.name}
                          onClick={() => {
                            //setRate(row.price);
                            setMainDataNewBill((adata) => ({
                              ...adata,
                              amount: row.price,
                              term: row.term,
                            }));
                          }}
                        >
                          {row?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Estado"
                    id="demo-simple-select"
                    sx={{ borderRadius: 2 }}
                    value={mainDataNewBill.status}
                    onChange={({ target }) =>
                      setMainDataNewBill((adata) => ({
                        ...adata,
                        status: target.value,
                      }))
                    }
                    error={newBillError.status}
                    required
                  >
                    <MenuItem value="Pendiente">Pendiente</MenuItem>
                    <MenuItem value="Cobrado">Cobrado</MenuItem>
                    <MenuItem value="Atrasado">Atrasado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Valor"
                  variant="outlined"
                  type="text"
                  id="amount"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  fullWidth
                  required
                  //debe carmbiar para poner nombre tarifa
                  value={mainDataNewBill.amount}
                  onChange={({ target }) =>
                    setMainDataNewBill((adata) => ({
                      ...adata,
                      amount: target.value,
                    }))
                  }
                  error={newBillError.amount}
                  onKeyDown={(event) => {
                    // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                    if (
                      !/[0-9]/.test(event.key) &&
                      event.key !== "Backspace" &&
                      event.key !== "Delete" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Plazo de cobro en días"
                InputProps={{
                  sx: { borderRadius: 2, mb: 1 },
                  endAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true, // Esto hará que el label no se mezcle con el value
                }}
                id="term"
                name="term"
                onChange={({ target }) =>
                  setMainDataNewBill((adata) => ({
                    ...adata,
                    term: target.value,
                  }))
                }
                error={newBillError.term}
                required
                value={mainDataNewBill?.term || ""}
                type="text"
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Comentario"
                  multiline
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  fullWidth
                  maxRows={8}
                  rows={2}
                  value={mainDataNewBill.method}
                  onChange={({ target }) =>
                    setMainDataNewBill((adata) => ({
                      ...adata,
                      method: target.value,
                    }))
                  }
                  error={newBillError.method}
                />
              </Grid>
              {mainDataQuery?.file && (
                <Grid item xs={12}>
                  <Box>
                    <Typography variant="body1" paddingLeft={1}>
                      Documento seleccionado:{" "}
                      <Chip
                        label={mainDataQuery?.file.name}
                        onDelete={() => {
                          setMainDataQuery((adata) => ({
                            ...adata,
                            file: "",
                          }));
                        }}
                        variant="outlined"
                      />
                    </Typography>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="body1">Registro</Typography>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  fullWidth
                  startIcon={<CloudUploadIcon />}
                  sx={{
                    mt: 1,
                    bgcolor: "secondary.main",
                    color: "primary.contrastText",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    ":hover": {
                      bgcolor: "#ebebeb",
                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    },
                  }}
                >
                  Adjuntar Documento
                  <VisuallyHiddenInput
                    type="file"
                    onChange={({ target }) => {
                      setMainDataQuery((adata) => ({
                        ...adata,
                        file: target.files[0],
                      }));
                    }}
                  />
                </Button>
              </Grid>
              {rates?.length < 1 && rates !== null && (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    El centro que seleccionaste no tiene tarifas, por favor crea
                    una tarifa nueva. Ten en cuenta que tu ingreso se guardará
                    con esta nueva tarifa.
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  onClick={() => {
                    setShowAdditionalFields(true);
                  }}
                  sx={{
                    bgcolor: "secondary.main",
                    color: "primary.contrastText",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    ":hover": {
                      bgcolor: "#ebebeb",
                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    },
                  }}
                >
                  <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
                  <Typography
                    variant="p1"
                    sx={{ fontWeight: 600, color: "black" }}
                  >
                    Nueva Tarifa
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            {showAdditionalFields && (
              <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    required
                    label="Nombre de la tarifa"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      sx: { borderRadius: 2 },
                    }}
                    onChange={({ target }) =>
                      setMainDataNewRate((adata) => ({
                        ...adata,
                        name: target.value,
                      }))
                    }
                    value={mainDataNewRate.name}
                    error={mainDataNewRateError.name}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    required
                    label="Valor"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      sx: { borderRadius: 2 },
                    }}
                    onChange={(event) => {
                      setMainDataNewRate((adata) => ({
                        ...adata,
                        price: event.target.value,
                      }));
                    }}
                    value={mainDataNewRate.price}
                    error={mainDataNewRateError.price}
                    onKeyDown={(event) => {
                      // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                      if (
                        !/[0-9]/.test(event.key) &&
                        event.key !== "Backspace" &&
                        event.key !== "Delete" &&
                        event.key !== "ArrowLeft" &&
                        event.key !== "ArrowRight"
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">
                      Cadencia
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      sx={{ borderRadius: 2, mb: 1 }}
                      label="cadence"
                      value={mainDataNewRate.concurrence}
                      onChange={({ target }) =>
                        setMainDataNewRate((adata) => ({
                          ...adata,
                          concurrence: target.value,
                        }))
                      }
                      error={mainDataNewRateError.concurrence}
                    >
                      <MenuItem value={"Mensual"}>Mensual</MenuItem>
                      <MenuItem value={"Quincenal"}>Quincenal</MenuItem>
                      <MenuItem value={"Por dia"}>Por dia</MenuItem>
                      <MenuItem value={"Por hora"}>Por hora</MenuItem>
                      <MenuItem value={"Por media hora"}>
                        Por media hora
                      </MenuItem>
                      <MenuItem value={"Por paciente"}>Por paciente</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          <Button
            onClick={() => {
              setRates(null);
              handleCloseModal();
            }}
            variant="contained"
            sx={{
              px: 5,
              bgcolor: "#fff",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
              textTransform: "none",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={SubmitmodalBill}
            variant="contained"
            sx={{
              px: 5,
              textTransform: "none",
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
