function DateCusmton(fechaOriginal) {
  if (!!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(fechaOriginal)) {
    const dateObject = new Date(fechaOriginal);
    const año = dateObject.getUTCFullYear();
    const mes = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0");
    const día = dateObject.getUTCDate().toString().padStart(2, "0");

    return `${día}-${mes}-${año}`;
  } else return fechaOriginal;
}

export default DateCusmton;

export function dateToInputCustom(fechaOriginal) {
  if (!!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(fechaOriginal)) {
    const dateObject = new Date(fechaOriginal);
    const año = dateObject.getUTCFullYear();
    const mes = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0");
    const día = dateObject.getUTCDate().toString().padStart(2, "0");

    return `${día}-${mes}-${año}`;
  } else return fechaOriginal;
}

export function getToday() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export const calculateEndTime = (startTime) => {
  const [hours, minutes] = startTime.split(":").map(Number);
  const endTime = new Date(0, 0, 0, hours, minutes + 30);
  const endHours = endTime.getHours().toString().padStart(2, "0");
  const endMinutes = endTime.getMinutes().toString().padStart(2, "0");
  return `${endHours}:${endMinutes}`;
};

/* export const generateHoursOptions = () => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    const hour = i.toString().padStart(2, "0");
    hours.push({ label: `${hour}:00`, value: `${hour}:00` });
    hours.push({ label: `${hour}:30`, value: `${hour}:30` });
  }
  return hours;
}; */
export const generateHoursOptions = () => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    const hour = i.toString().padStart(2, "0");
    hours.push({ label: `${hour}:00`, value: `${hour}:00` });
    hours.push({ label: `${hour}:15`, value: `${hour}:15` });
    hours.push({ label: `${hour}:30`, value: `${hour}:30` });
    hours.push({ label: `${hour}:45`, value: `${hour}:45` });
  }
  return hours;
};

export const getUniqueYears = (invoices) => {
  const years = invoices.map((invoice) =>
    new Date(invoice.date).getUTCFullYear()
  );
  return Array.from(new Set(years)); // Eliminar duplicados
};
