import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  Stack,
  IconButton,
  Card,
  Alert,
  Chip,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GlobalHeader from "../../components/header";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import useApi from "../../hooks/useApi";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useParams } from "react-router-dom";
import dateToInput from "../../utils/DateToInput";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import BackClients from "../info_client/back";
import { TapsHeaders } from "../../components/tapsHeaders";
import DeleteRegisterFile from "../info_client/deleteFile";
import DeleteRegister from "../info_client/deleteRegister";
import { useNavigate } from "react-router-dom";
import SnackAlert from "../../components/SnackAlert";
import ModalPatient from "../info_client/modalPatient";
import {
  ValidateInfo,
  ValidateModalAndBill,
  ValidateModal,
} from "../../utils/validateModal";
import "./register.css";

export default function RegisterData() {
  const { clientid } = useParams();
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [centers, setCenters] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openModaldelete, setOpenModaldelete] = useState(false);
  const [openModaldeleteFile, setOpenModaldeleteFile] = useState(false);
  const [namecomplete, setNamecomplete] = useState("");
  const [photo_profile, setPhoto_profile] = useState("");
  const [registros, setRegistros] = useState([]);
  const [enableAdditionalFields, setEnableAdditionalFields] = useState(true);
  const [centersfiltred, setCentersfiltred] = useState();

  const [info, setInfo] = useState({
    address: null,
    email: null,
    lastname: null,
    name: null,
    national_id: "",
    note: null,
    phone: null,
  });

  const [infoError, setInfoError] = useState({
    address: false,
    email: false,
    lastname: false,
    name: false,
    national_id: false,
    note: false,
    phone: false,
  });

  const [mainData, setMainData] = useState({
    center_id: "",
    date: new Date().toISOString().split("T")[0],
    note: "",
    file: "",
  });

  const [mainDataError, setMainDataError] = useState({
    center_id: false,
    date: false,
    note: false,
    file: false,
  });

  const [mainDataNewBill, setMainDataNewBill] = useState({
    center_id: "",
    client_id: clientid,
    name_rate: "",
    amount: "",
    date: new Date().toISOString().split("T")[0],
    status: "",
    method: "",
  });

  const [newBillError, setNewBillError] = useState({
    center_id: false,
    client_id: false,
    name_rate: false,
    amount: false,
    date: false,
    status: false,
    method: false,
  });

  useEffect(() => {
    loadApi("center/centers", true, "get")
      .then((response) => {
        setCenters(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  }, []);

  useEffect(() => {
    loadApi("center/centers_on", true, "get")
      .then((response) => {
        setCentersfiltred(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  }, []);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setMainDataError({
      center_id: false,
      date: false,
      note: false,
      file: false,
    });

    setMainData({
      center_id: "",
      date: "",
      note: "",
      file: "",
    });

    setMainDataNewBill({
      center_id: "",
      client_id: clientid,
      name_rate: "",
      amount: "",
      date: "",
      status: "",
      method: "",
    });

    setNewBillError({
      center_id: false,
      client_id: false,
      name_rate: false,
      amount: false,
      date: false,
      status: false,
      method: false,
    });
  };
  const handleCloseModaldelete = () => {
    setOpenModaldelete(false);
  };

  // consulta la foto del profesional
  useEffect(() => {
    setOpenbackd(true);
    loadApi("profile", true, "get")
      .then((response) => {
        setPhoto_profile(response.data.photo_profile.url);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);

  useEffect(() => {
    setOpenbackd(true);
    loadApi(`client/client/${clientid}`, true, "get")
      .then((response) => {
        setInfo(response.data);

        setNamecomplete(response.data.name + " " + response.data.lastname);
      })
      .catch((e) => {
        showSnack(e.message);
        if (e.message) {
          setTimeout(() => {
            navigate("/clients");
          }, 800);
        }
        setOpenbackd(true);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);

  useEffect(() => {
    setOpenbackd(true);
    loadApi(`querie/queries/${clientid}`, true, "get")
      .then((response) => {
        setRegistros(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);

  const handleSubmitmodal = async (e) => {
    e.preventDefault();
    if (!ValidateModal(mainData, setMainDataError)) return;

    if (enableAdditionalFields === true) {
      //se manda sin factura
      setOpenModal(false);
      setOpenbackd(true);
      loadApi(
        `querie/${clientid}`,
        true,
        "post",
        {
          ...mainData,
        },
        true
      )
        .then((response) => {
          setRegistros((adata) => {
            const newRecordData = [...adata, response.data.recordData];
            newRecordData.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newRecordData;
          });
          setMainData({
            center_id: "",
            date: "",
            note: "",
          });
          showSnack(response.data.message, "success");
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
          handleCloseModal();
        });
    } else {
      // se mandan con factura
      if (!ValidateModalAndBill(mainDataNewBill, setNewBillError)) return;
      setOpenModal(false);
      setOpenbackd(true);
      loadApi(
        `querie/${clientid}`,
        true,
        "post",
        {
          ...mainData,
        },
        true
      )
        .then((response) => {
          setRegistros((adata) => {
            const newRecordData = [...adata, response.data.recordData];
            newRecordData.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newRecordData;
          });
          setMainData({
            center_id: "",
            date: "",
            note: "",
          });
          showSnack(response.data.message, "success");
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
          handleCloseModal();
        });
      //-------
      loadApi("invoice", true, "post", {
        ...mainDataNewBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    }
  };

  const VisuallyHiddenInput = (props) => (
    <input
      type="file"
      style={{ display: "none" }}
      accept=".xlsx,.xls,.doc,.docx,.pdf,.txt,image/*"
      {...props}
    />
  );
  const deleteregister = (id) => {
    setOpenbackd(true);
    loadApi(`querie/delete/${id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success", 1000);
        //eliminar por id del registro
        setRegistros((adata) => adata.filter((aitem) => aitem.id !== id));
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  const updateRegister = (id, index) => {
    setOpenbackd(true);

    loadApi(
      `querie/update_querie/${id}`,
      true,
      "put",
      {
        ...registros[index],
      },
      true
    )
      .then((response) => {
        showSnack(response.data.message, "success");
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  useEffect(() => {
    if (!info.name && info.name !== null) {
      setInfoError((adata) => ({
        ...adata,
        name: true,
      }));
    } else {
      setInfoError((adata) => ({
        ...adata,
        name: false,
      }));
    }
    if (!info.lastname && info.lastname !== null) {
      setInfoError((adata) => ({
        ...adata,
        lastname: true,
      }));
    } else {
      setInfoError((adata) => ({
        ...adata,
        lastname: false,
      }));
    }
  }, [info.name, info.lastname]);
  const deleteregisterFile = (id, index) => {
    setRegistros((adata) => {
      let newData = [...adata];
      newData[index].file = "";

      return newData;
    });
    setOpenbackd(true);
    loadApi(
      `querie/update_querie/${id}`,
      true,
      "put",
      {
        ...registros[index],
      },
      true
    )
      .then((response) => {
        showSnack(response.data.message, "success");
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };

  const menu = [
    { label: "Datos", path: `/client/${clientid}` },
    { label: "Historial", path: `/client/register/${clientid}` },
    { label: "Agenda", path: `/client/diary/${clientid}` },
    { label: "Cobros", path: `/client/bill/${clientid}` },
    { label: "Comunicaciones", path: `/client/chat/${clientid}` },
  ];

  return (
    <>
      <GlobalHeader />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
          minHeight: "91vh",
        }}
      >
        <BackClients />
        <TapsHeaders
          clientid={clientid}
          namecomplete={namecomplete}
          menu={menu}
        >
          <Button
            variant="contained"
            onClick={handleClickOpenModal}
            sx={{
              bgcolor: "secondary.main",
              color: "primary.contrastText",
              marginTop: "17%",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
            <Typography variant="p1" sx={{ fontWeight: 600, color: "black" }}>
              Nuevo registro
            </Typography>
          </Button>
        </TapsHeaders>

        {registros?.length < 1 && (
          <Stack mt={2}>
            <Alert severity="warning">El paciente no tiene registros.</Alert>
          </Stack>
        )}
        <Divider sx={{ my: 2 }} />
        {registros &&
          (registros || [])?.map((row, index) => (
            <>
              <Box sx={{ paddingX: 2, marginBottom: 2 }}>
                <Grid container direction="row" spacing={2} sx={{ my: 1 }}>
                  <Grid item xs={12} sm={12} md={4} lg={4} px={2}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">
                        Centro
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={{ borderRadius: 2, mb: 1 }}
                        InputProps={{
                          sx: { borderRadius: 2 },
                        }}
                        type="text"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={registros[index].center_id}
                        onChange={({ target }) =>
                          setRegistros((adata) => {
                            let newData = [...adata];
                            newData[index].center_id = target.value;

                            return newData;
                          })
                        }
                        label="Centro"
                        required
                      >
                        <MenuItem value="" disabled>
                          Seleccione una opción...
                        </MenuItem>
                        {centers &&
                          (centers || [])?.map((row, index) => (
                            // recorrer centros row.id sera value y se mostrara row.name
                            <MenuItem value={row.id}>{row.name}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} px={2}>
                    <TextField
                      label="Fecha"
                      InputProps={{
                        sx: { borderRadius: 2 },
                      }}
                      variant="outlined"
                      fullWidth
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={dateToInput(registros[index].date)}
                      onChange={({ target }) =>
                        setRegistros((adata) => {
                          let newData = [...adata];
                          newData[index].date = target.value;
                          return newData;
                        })
                      }
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4} px={2}>
                    {registros[index]?.file < 1 && (
                      <Stack>
                        <Alert
                          sx={{
                            minHeight: { md: "40px" },
                            backgroundColor: "white",
                            borderRadius: 2,
                            margin: 0, // Elimina el margen por defecto
                            boxShadow: "none", // Elimina la sombra por defecto
                          }}
                          severity="info"
                        >
                          Sin documento
                        </Alert>
                      </Stack>
                    )}

                    {registros[index]?.file.length !== 0 ? (
                      <div>
                        <Typography variant="body1">
                          <Chip
                            sx={{
                              width: "100%",
                              minHeight: { md: "55px" },
                              borderRadius: 2,
                            }}
                            icon={<FileDownloadIcon />}
                            label={registros[index].file.name}
                            onClick={() => {
                              const fileUrl = registros[index].file.url; // Asegúrate de que esto sea una URL válida como string
                              if (typeof fileUrl === "string") {
                                const a = document.createElement("a");
                                a.href = fileUrl;
                                a.download = registros[index].file.name;
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                              }
                            }}
                            onDelete={() => {
                              setOpenModaldeleteFile({
                                id: registros[index].id,
                                index: index,
                              });
                            }}
                            variant="outlined"
                          />
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} px={2}>
                    <TextField
                      label="Nota"
                      multiline
                      InputProps={{
                        sx: { borderRadius: 2 },
                      }}
                      type="text"
                      variant="outlined"
                      fullWidth
                      minRows={2}
                      maxRows={6}
                      InputLabelProps={{ shrink: true }}
                      value={registros[index].note}
                      onChange={({ target }) =>
                        setRegistros((adata) => {
                          let newData = [...adata];
                          newData[index].note = target.value;
                          return newData;
                        })
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    px={4}
                    align="center"
                  >
                    <IconButton
                      title="Cargar"
                      aria-label="load"
                      size="large"
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      fullWidth
                      color="secondary"
                      sx={{
                        bgcolor: "#f9e79f",
                        ":hover": {
                          bgcolor: "#F0E5C5 !important",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                    >
                      <CloudUploadIcon fontSize="inherit" />
                      <VisuallyHiddenInput
                        type="file"
                        onChange={({ target }) => {
                          const updateRegister = (id, data) => {
                            setOpenbackd(true);
                            loadApi(
                              `querie/update_querie/${id}`,
                              true,
                              "put",
                              {
                                ...data,
                              },
                              true
                            )
                              .then((response) => {
                                showSnack(response.data.message, "success");
                              })
                              .catch((e) => {
                                showSnack(e.message);
                              })
                              .finally((e) => {
                                setOpenbackd(false);
                              });
                          };

                          let finalTempData;

                          let file = target.files[0];
                          if (file) {
                            setRegistros((adata) => {
                              let newData = [...adata];
                              newData[index].file = target.files[0];
                              finalTempData = newData[index];

                              return newData;
                            });

                            updateRegister(registros[index].id, finalTempData);
                          }
                        }}
                      />
                    </IconButton>

                    <IconButton
                      aria-label="delete"
                      title="Eliminar"
                      size="large"
                      onClick={() => {
                        setOpenModaldelete(registros[index].id);
                      }}
                      color="secondary"
                      sx={{
                        mx: 1,
                        bgcolor: "#f5b7b1",
                        ":hover": {
                          bgcolor: "#DEBFBF",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      title="Guardar"
                      aria-label="save"
                      size="large"
                      onClick={() => {
                        updateRegister(registros[index].id, index);
                      }}
                      color="secondary"
                      sx={{
                        bgcolor: "#aed6f1",
                        ":hover": {
                          bgcolor: "#C1CEE8!important",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                    >
                      <SaveOutlinedIcon fontSize="inherit" />
                    </IconButton>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
                </Grid>
              </Box>
              {registros.length !== 1 && index !== registros.length - 1 && (
                <Divider sx={{ mb: 2 }} />
              )}
            </>
          ))}
      </Container>

      <ModalPatient
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        centers={centersfiltred}
        mainData={mainData}
        setMainData={setMainData}
        handleSubmitmodal={handleSubmitmodal}
        mainDataError={mainDataError}
        VisuallyHiddenInput={VisuallyHiddenInput}
        CloudUploadIcon={CloudUploadIcon}
        loadApi={loadApi}
        mainDataNewBill={mainDataNewBill}
        setMainDataNewBill={setMainDataNewBill}
        enableAdditionalFields={enableAdditionalFields}
        setEnableAdditionalFields={setEnableAdditionalFields}
        newBillError={newBillError}
      />
      <DeleteRegister
        openModaldelete={openModaldelete}
        handleCloseModaldelete={handleCloseModaldelete}
        deleteregister={deleteregister}
      />
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <DeleteRegisterFile
        openModaldeleteFile={openModaldeleteFile}
        setOpenModaldeleteFile={setOpenModaldeleteFile}
        deleteregisterFile={deleteregisterFile}
      />
    </>
  );
}
