import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { updateDiaryAvailability } from "./fetch";

export function ModalAvailability({
  open,
  onClose,
  onSave,
  initialData,
  centers,
  showSnack,
  loadApi,
}) {
  const [centerId, setCenterId] = useState("");
  const [day, setDay] = useState("");
  const [startHour, setStartHour] = useState("");
  const [endHour, setEndHour] = useState("");

  // Lista de días y horas para ejemplos
  const days = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes"];

  /*   const generateHoursOptions = () => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      const hour = i.toString().padStart(2, "0");
      hours.push({ label: `${hour}:00`, value: `${hour}:00` });
      hours.push({ label: `${hour}:30`, value: `${hour}:30` });
    }
    return hours;
  }; */
  const generateHoursOptions = () => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      const hour = i.toString().padStart(2, "0");
      hours.push({ label: `${hour}:00`, value: `${hour}:00` });
      hours.push({ label: `${hour}:15`, value: `${hour}:15` });
      hours.push({ label: `${hour}:30`, value: `${hour}:30` });
      hours.push({ label: `${hour}:45`, value: `${hour}:45` });
    }
    return hours;
  };

  const hours = generateHoursOptions();

  useEffect(() => {
    if (initialData) {
      setCenterId(initialData.center_id || "");
      setDay(initialData.day || "");
      setStartHour(initialData.startHour || "");
      setEndHour(initialData.endHour || "");
    }
  }, [initialData]);

  const handleSave = () => {
    if (!centerId || !day || !startHour || !endHour) {
      return;
    }

    if (startHour >= endHour) {
      return;
    }

    // Crear el nuevo objeto de disponibilidad
    const updatedEntry = {
      id: initialData ? initialData.id : Date.now(), // Usar el ID del initialData si está presente
      center_id: centerId,
      day,
      startHour,
      endHour,
    };

    // Llamar a la función de actualización con los datos actualizados
    updateDiaryAvailability(updatedEntry, onSave, onClose, loadApi, showSnack);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {initialData ? "Editar Disponibilidad" : "Agregar Disponibilidad"}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={2}>
          <FormControl fullWidth>
            <InputLabel>Centro</InputLabel>
            <Select
              value={centerId}
              onChange={(e) => setCenterId(e.target.value)}
              label="Centro"
            >
              {centers?.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Día</InputLabel>
            <Select
              value={day}
              onChange={(e) => setDay(e.target.value)}
              label="Día"
            >
              {days.map((d) => (
                <MenuItem key={d} value={d}>
                  {d}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Hora de Inicio</InputLabel>
            <Select
              value={startHour}
              onChange={(e) => setStartHour(e.target.value)}
              label="Hora de Inicio"
            >
              {hours.map((h) => (
                <MenuItem key={h.value} value={h.value}>
                  {h.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Hora de Fin</InputLabel>
            <Select
              value={endHour}
              onChange={(e) => setEndHour(e.target.value)}
              label="Hora de Fin"
            >
              {hours.map((h) => (
                <MenuItem key={h.value} value={h.value}>
                  {h.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
