import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Link,
} from "@mui/material";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useEffect, useState } from "react";
import { fetchOccupation } from "../../utils/profesion";

export default function ModalCreateAppo({
  openModalCreateAppo,
  setOpenModalCreateAppo,
  setMainDataCreate,
  mainDataCreate,
  centers,
  clients,
  loadApi,
  createError,
  SubmitmodalCreateAppo,
  setCreateError,
  setOpenModalclient,
  showSnack,
}) {
  const [mappedOptions, setMappedOptions] = useState([]);
  const [occupation, setOccupation] = useState();
  useEffect(() => {
    fetchOccupation(loadApi, showSnack, setOccupation);
    loadApi(`client/client/${mainDataCreate.client_id}`, true, "get")
      .then((response) => {
        setMainDataCreate((adata) => ({
          ...adata,
          phone: response.data.phone,
        }));
      })
      .catch((e) => {})
      .finally((e) => {});
  }, [mainDataCreate.client_id]);

  useEffect(() => {
    const options = (clients || []).map((item) => ({
      label: `${item.name} ${item.lastname}`,
      value: item.id,
    }));

    setMappedOptions(options);
  }, [clients]);

  /*   const generateHoursOptions = () => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      const hour = i.toString().padStart(2, "0");
      hours.push({ label: `${hour}:00`, value: `${hour}:00` });
      hours.push({ label: `${hour}:30`, value: `${hour}:30` });
    }
    return hours;
  }; */
  const generateHoursOptions = () => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      const hour = i.toString().padStart(2, "0");
      hours.push({ label: `${hour}:00`, value: `${hour}:00` });
      hours.push({ label: `${hour}:15`, value: `${hour}:15` });
      hours.push({ label: `${hour}:30`, value: `${hour}:30` });
      hours.push({ label: `${hour}:45`, value: `${hour}:45` });
    }
    return hours;
  };

  const hoursOptions = generateHoursOptions();

  const calculateEndTime = (startTime) => {
    const [hours, minutes] = startTime.split(":").map(Number);
    const endTime = new Date(0, 0, 0, hours, minutes + 30);
    const endHours = endTime.getHours().toString().padStart(2, "0");
    const endMinutes = endTime.getMinutes().toString().padStart(2, "0");
    return `${endHours}:${endMinutes}`;
  };

  const handleStartTimeChange = (event) => {
    const startTime = event.target.value;
    const endTime = calculateEndTime(startTime);
    setMainDataCreate((adata) => ({
      ...adata,
      startAt: startTime,
      endAt: endTime,
    }));
  };

  return (
    <>
      <Dialog
        open={!!openModalCreateAppo}
        onClose={() => {
          setOpenModalCreateAppo(false);
        }}
        PaperProps={{
          component: "form",
        }}
        disableScrollLock
        sx={{ overflow: "hidden", zIndex: "500 !important" }}
      >
        <DialogTitle>Nuevo Ingreso</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: { xs: "auto", md: 500 } }}>
            {centers?.length < 1 && (
              <Stack mb={1}>
                <Alert severity="warning">
                  Aún no tienes centros, crea un nuevo centro{" "}
                  <Link className="link" href="/profile" color="inherit">
                    aquí
                  </Link>
                </Alert>
              </Stack>
            )}
            <Grid
              container
              direction="row"
              spacing={1}
              marginBottom={2}
              mt={0.2}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">Centro</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{ borderRadius: 2 }}
                    value={mainDataCreate.center_id}
                    onChange={({ target }) =>
                      setMainDataCreate((adata) => ({
                        ...adata,
                        center_id: target.value,
                      }))
                    }
                    label="Centro"
                    required
                    error={createError.center_id}
                  >
                    {" "}
                    <MenuItem value="" disabled>
                      Seleccione una opción...
                    </MenuItem>
                    {centers &&
                      (centers || []).map((row, index) => (
                        // recorrer centros row.id sera value y se mostrara row.name
                        <MenuItem key={row.id} value={row.id}>
                          {row.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack direction="row" spacing={1}>
                  <Autocomplete
                    label={occupation === "Clientes" ? "Cliente" : "Paciente"}
                    fullWidth
                    options={mappedOptions}
                    getOptionLabel={(option) => option.label}
                    value={
                      mappedOptions.find(
                        (option) => option.value === mainDataCreate.client_id
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setMainDataCreate((adata) => ({
                        ...adata,
                        client_id: newValue ? newValue.value : null,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label={
                          occupation === "Clientes" ? "Cliente" : "Paciente"
                        }
                        {...params}
                        variant="outlined"
                        error={createError.client_id}
                      />
                    )}
                  />
                  <Button
                    sx={{
                      width: "29%",
                      bgcolor: "secondary.main",
                      color: "primary.contrastText",

                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      ":hover": {
                        bgcolor: "#ebebeb",
                        boxShadow:
                          "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      },
                    }}
                    onClick={() => {
                      setOpenModalclient(true);
                    }}
                  >
                    <AddOutlinedIcon />
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} marginBottom={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Fecha"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  variant="outlined"
                  fullWidth
                  onChange={({ target }) =>
                    setMainDataCreate((adata) => ({
                      ...adata,
                      date: target.value,
                    }))
                  }
                  value={mainDataCreate?.date}
                  required
                  error={createError?.date}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Celular"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  id="Name" // ID personalizado
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={mainDataCreate?.phone}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) =>
                    setMainDataCreate((adata) => ({
                      ...adata,
                      phone: target.value,
                    }))
                  }
                  error={createError.phone}
                  onKeyDown={(event) => {
                    // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                    if (
                      !/[0-9]/.test(event.key) &&
                      event.key !== "Backspace" &&
                      event.key !== "Delete" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} marginBottom={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="start-time-label">Hora Inicio</InputLabel>
                  <Select
                    labelId="start-time-label"
                    value={mainDataCreate.startAt}
                    onChange={handleStartTimeChange}
                    label="Hora Inicio"
                    error={createError.startAt}
                  >
                    {hoursOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel id="end-time-label">Hora Fin</InputLabel>
                  <Select
                    labelId="end-time-label"
                    value={mainDataCreate.endAt}
                    onChange={({ target }) =>
                      setMainDataCreate((adata) => ({
                        ...adata,
                        endAt: target.value,
                      }))
                    }
                    label="Hora Fin"
                    error={createError.endAt}
                  >
                    {hoursOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Stack>
              <TextField
                id="outlined-textarea"
                label="Nota"
                placeholder="Nota"
                multiline
                fullWidth
                rows={2}
                InputProps={{
                  sx: { borderRadius: 2 },
                }}
                InputLabelProps={{ shrink: true }}
                value={mainDataCreate?.note}
                onChange={({ target }) =>
                  setMainDataCreate((adata) => ({
                    ...adata,
                    note: target.value,
                  }))
                }
                error={createError.note}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          <Button
            onClick={() => {
              setOpenModalCreateAppo(false);
              setMainDataCreate({
                center_id: "",
                client_id: "",
                date: "",
                startAt: "",
                endAt: "",
                phone: "",
                note: "",
              });
              setCreateError({
                center_id: false,
                client_id: false,
                date: false,
                startAt: false,
                endAt: false,
                phone: false,
                note: false,
              });
            }}
            variant="contained"
            sx={{
              px: 5,
              bgcolor: "#fff",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
              textTransform: "none",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={SubmitmodalCreateAppo}
            variant="contained"
            sx={{
              px: 5,
              textTransform: "none",
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
